var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_c('c-box', {
    attrs: {
      "background-color": _vm.style.color,
      "align-self": "flex-start",
      "padding": "1px 10px",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "font-size": ['14px', '16px']
    }
  }, [_c('c-text', {
    attrs: {
      "color": "neutral.white"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1), _c('c-box', {
    attrs: {
      "margin-top": "4px",
      "background-color": _vm.style.softColor,
      "border": "2px solid",
      "border-color": _vm.style.color,
      "padding": "8px",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    staticClass: "items",
    style: "--color: ".concat(_vm.style.colorHex, ";")
  }, [_vm._t("items", function () {
    return [typeof _vm.items === 'string' ? _c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.items)
      }
    }) : _c('c-box', {
      attrs: {
        "as": "ul",
        "padding-left": "27px"
      }
    }, _vm._l(_vm.items, function (item, index) {
      return _c('c-box', {
        key: index,
        attrs: {
          "as": "li",
          "color": _vm.style.color,
          "font-size": ['14px', '16px']
        }
      }, [_c('c-text', {
        attrs: {
          "color": _vm.style.color,
          "font-weight": "500"
        }
      }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
    }), 1)];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }